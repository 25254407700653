import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {  StagesDataType } from './utils/generatePipeline';
import { LevelType, TaskType, UserType } from "./CreatePipelineController";
import { toast } from "react-toastify";


export interface PipelineType {
  "id": string,
  "type":string,
  "attributes": {
      "pipeline_name": string,
      "stages": [],
      "description": string,
      "status": string,
      "permission_level": string,
      "account": [],
      clinic_name?:string
      created_at: string,
      updated_at:string,
      pipeline_serial_id:string
      users:UserType[]
      "taskGroup":TaskType[]
      "permission":LevelType[]
      rules?:any[]
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipelineData: PipelineType[]
  singlePipeline: PipelineType['attributes']
  pipeline_id: number | null | string
  pipeline_name:string
  isDeletePipelineModal: boolean
  singlePipelineDetails:PipelineType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PipelineDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dragIndexGlobal: any
  dragRuleIndex:any
  deletePipelineCallId: string="";
  pipelineDetailsCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pipelineDetailsAPI()
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipelineData: [],
      singlePipeline: {
        pipeline_name: "",
        stages: [],
        description: "",
        status: "",
        permission_level: "",
        account: [],
        created_at: "",
        updated_at: "",
        pipeline_serial_id: "",
        users: [],
        taskGroup: [],
        permission: [],
      },
      isDeletePipelineModal: false,
      pipeline_id: null,
      pipeline_name:"",
      singlePipelineDetails:{
        id: "",
        type: "",
        attributes: {
          pipeline_name: "",
          stages: [],
          description: "",
          status: "",
          permission_level: "",
          account: [],
          clinic_name: undefined,
          created_at: "",
          updated_at: "",
          pipeline_serial_id: "",
          users: [],
          taskGroup: [],
          permission: [],
          rules: undefined
        }
      }

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
     let errorResponse  =message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.deletePipelineCallId:this.handleDeletePipelineRes(responseJson, errorResponse);
            break; 
          case this.pipelineDetailsCallId:this.handlePipelineDetailsRes(responseJson,errorResponse)
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  }

gotoList=()=>{
  this.props.navigation.replace("Cfpipelines1")
}
  handleDeletePipelineRes=(responseJson:any,errorRes: string)=>{
    if(responseJson.deleted){
          this.setState({ isDeletePipelineModal: false })
    toast.success("Pipeline Deleted Successfully", { style: { fontSize: "1.6rem" } })
    this.props.navigation.goBack()
  }else{
      this.setState({isDeletePipelineModal: false },()=> toast.error("Record not found", { style: { fontSize: "1.6rem" } }))
    }
  }
  handlePipelineDetailsRes=(responseJson:any,errorRes: string)=>{
if(responseJson.data){
  responseJson.data.attributes.users = [
    {
      id: "1",
      type: "user",
      attributes: {
        id: 1,
        label: "John Smith",
        user_name: "John Smith",
        source: "https://randomuser.me/api/portraits/men/1.jpg"
      }
    }
  ];
  
  responseJson.data.attributes.taskGroup = [
    {
      id: "4",
      type: "task",
      attributes: {
        id: 4,
        label: "task 3",
        task_name: "task 3"
      }
    }
  ];
  responseJson.data.attributes.clinic_name="clinic1"

  this.setState({ singlePipeline: responseJson.data.attributes,
    pipeline_id: responseJson.data.id,
     pipeline_name:responseJson.data.attributes.pipeline_name,
     singlePipelineDetails:responseJson.data })
}
  }

  pipelineDetailsAPI=async ()=>{
    let pipelineID= localStorage.getItem("pipelineID")
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pipelineDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pipelineListApiEndPoint+"/"+pipelineID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  handleStageView = (item: StagesDataType) => {
    localStorage.setItem("stage", JSON.stringify(item));
    this.props.navigation.navigate("Cfstage1details", { stageId: item.id, pipelineId: item.pipeline_id })
  }
  handlePipelineEdit = () => {
    this.props.navigation.navigate("Editpipeline", { pipelineData:this.state.singlePipelineDetails })
  }

  handleBackClick = (sliderRef: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  handleNextClick = (sliderRef: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  handleDrag = (e: any, dragIndex: number) => {
    this.dragIndexGlobal = dragIndex
  }
  handleDrop = (e: any, dropIndex: number) => {
    e.preventDefault()

  }
  handleDragRule = (e: any, dragIndex: number) => {
    this.dragRuleIndex = dragIndex
  }
  handleDropRule = (e: any, dropIndex: number) => {
    e.preventDefault()
  }
  handleAddRule = () => {
    this.props.navigation.navigate("CreatePipelineRule",{})
  }
  handleDeleteToggle = () => {
    this.toggleDeleteModal()
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({ isDeletePipelineModal: !prevState.isDeletePipelineModal }))
  }
  handleDeletePipeline = (event: any) => {
    event?.preventDefault()
    const id = this.state.pipeline_id

    const token = localStorage.getItem("token")

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));

    this.deletePipelineCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePipelineApiEndPoint}${id}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
