import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface PipelineEditErrorType {
    pipeline_name?: string,
    description?: string,
}
import { toast } from "react-toastify"
import { generateRandomUsers, PipelineType } from "./utils/generatePipeline";
import { LevelType, TaskType, UserType } from "./CreatePipelineController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    pipeline_name: string;
    description: string;
    editPipelineErrors: PipelineEditErrorType
    selectedTasks: TaskType[]
    editTasksData: TaskType[]
    associatedClinic: string
    editLevelsData: LevelType[]
    editUsersData: UserType[]
    selectedUsers: UserType[]
    selectedLevels: LevelType[]
    singlePipeline: PipelineType
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditpipelineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    editPipelineAPICallId: string="";
    editLevelsListCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.editLevelsListAPI()
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            pipeline_name: '',
            description: '',
            editPipelineErrors: {},
            selectedTasks: [],
            selectedUsers: [],
            selectedLevels: [],
            editTasksData: [
                {
                    "id": "2",
                    "type": "permission",
                    "attributes": {
                        "id": 2,
                        "label": "task 1",
                        "task_name": "task 1"            
                      }
                },
                {
                    "id": "3",
                    "type": "task",
                    "attributes": {
                        "id": 3,
                        "label": "task 2",
                        "task_name": "task 2"
                    }
                },
                {
                    "id": "4",
                    "type": "task",
                    "attributes": {
                        "id": 4,
                        "label": "task 3",
                        "task_name": "task 3"
                    }
                }
            ],
            editLevelsData: [
                {
                    "id": "2",
                    "type": "permission",
                    "attributes": {
                        "id": 2,
                        "label": "Level 1",
                        "permission_level": "level1"
                    }
                },
                {
                    "id": "3",
                    "type": "permission",
                    "attributes": {
                        "id": 3,
                        "label": "Level 2",
                        "permission_level": "level2"
                    }
                },
                {
                    "id": "4",
                    "type": "permission",
                    "attributes": {
                        "id": 4,
                        "label": "Level 3",
                        "permission_level": "level3"
                    }
                }
            ],
            editUsersData: [
                {"id": "1",
                    "type": "user",
                    "attributes": {"id": 1,
                        "label": "John Smith",
                        "user_name": "John Smith",
                        "source": "https://randomuser.me/api/portraits/men/1.jpg"
                    }
                },
                {"id": "2",
                    "type": "user",
                    "attributes": {"id": 2,
                        "label": "Michael Johnson",
                        "user_name": "Michael Johnson",
                        "source": "https://randomuser.me/api/portraits/men/2.jpg"
                    }
                },
                {"id": "3",
                    "type": "user",
                    "attributes": {"id": 3,
                        "label": "James Brown",
                        "user_name": "James Brown",
                        "source": "https://randomuser.me/api/portraits/men/3.jpg"
                    }
                },
                {"id": "4",
                    "type": "user",
                    "attributes": {"id": 4,
                        "label": "Robert Williams",
                        "user_name": "Robert Williams",
                        "source": "https://randomuser.me/api/portraits/men/4.jpg"
                    }
                },
                {"id": "5",
                    "type": "user",
                    "attributes": {"id": 5,
                        "label": "David Jones",
                        "user_name": "David Jones",
                        "source": "https://randomuser.me/api/portraits/men/5.jpg"
                    }
                },
                {"id": "6",
                    "type": "user",
                    "attributes": {"id": 6,
                        "label": "Mary Miller",
                        "user_name": "Mary Miller",
                        "source": "https://randomuser.me/api/portraits/women/1.jpg"
                    }
                },
                {"id": "7",
                    "type": "user",
                    "attributes": {"id": 7,
                        "label": "Jennifer Davis",
                        "user_name": "Jennifer Davis",
                        "source": "https://randomuser.me/api/portraits/women/2.jpg"
                    }
                },
                {"id": "8",
                    "type": "user",
                    "attributes": { "id": 8,
                        "label": "Patricia Garcia",
                        "user_name": "Patricia Garcia",
                        "source": "https://randomuser.me/api/portraits/women/3.jpg"
                    }
                },
                {"id": "9",
                    "type": "user",
                    "attributes": { "id": 9,
                        "label": "Linda Martinez",
                        "user_name": "Linda Martinez",
                        "source": "https://randomuser.me/api/portraits/women/4.jpg"
                    }
                }
            ],
            associatedClinic: '',
            singlePipeline: {
                id: '', pipeline_name: '',
                description: '',
                created_at: '',
                updated_at: '',
                permission_level: '',
                pipeline_serial_id: '',
                status: '',
                order: null,
                stages: [],
                clinic_name: '',
                visibleBy: { IndividualUsers: [], PermissionLevels: [], TaskGroups: [] },
                rules: []
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            )
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
           let errorResponse  =message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            )
            if (apiRequestCallId) {
              switch (apiRequestCallId) {
                case this.editPipelineAPICallId:
                  this.handleEditPipelineRes(responseJson, errorResponse);
                  break;
                case this.editLevelsListCallId:
                  this.handleEditLevelsListRes(responseJson,errorResponse)
                
              }
            }
          }
        // Customizable Area End
    }
    // Customizable Area Start
    handleEditPipelineRes=(responseJson:any,errorRes: string)=>{
        if (!responseJson.error) {
             toast.success("Pipeline edited successfully", { style: { fontSize: "1.6rem" } })
             localStorage.setItem("pipelineID",responseJson.id)
        this.props.navigation.replace("PipelineDetail", { pipelineData: responseJson })
    
        }
      }
      handleEditLevelsListRes=(responseJson:any,errorResponse:any)=>{
        if(responseJson.data){
          this.setState({editLevelsData:responseJson.data,})
        }
      }
    isEditPipelineFormComplete = () => {
        const { pipeline_name, description, selectedLevels, selectedTasks, selectedUsers, associatedClinic } = this.state;
        return (
            pipeline_name &&
            description &&
            associatedClinic && selectedLevels.length > 0 && selectedUsers.length > 0 && selectedTasks.length > 0
        );
    };

    async componentDidMount() {
        super.componentDidMount()
        let pipelineData=this.props.navigation.getParam('pipelineData')
        let data=pipelineData?.attributes
        console.log("pipelineData",pipelineData)
        const levelsDataWithLabel = data.permission.map((level: { label: any; }) => ({ ...level, label: level.label }))
        const usersDataWithLabel = data.users.map((user: { label: any; }) => ({ ...user, label: user.label }))
        const tasksDataWithLabel = data.taskGroup.map((task: { label: any; }) => ({ ...task, label: task.label }))
        this.setState({ associatedClinic: data.clinic_name, pipeline_name: data.pipeline_name, description: data.description, selectedLevels: levelsDataWithLabel, selectedTasks: tasksDataWithLabel, selectedUsers: usersDataWithLabel })
    }

    handleEditChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        this.setState((prevState) => {
            const updatedErrors = { ...prevState.editPipelineErrors };

            if (name === "pipeline_name" && value.length <= 100) {
                delete updatedErrors.pipeline_name;
            }

            if (name === "description" && value.length <= 300) {
                delete updatedErrors.description;
            }

            return {
                ...prevState,
                [name]: value,
                editPipelineErrors: updatedErrors,
            };
        });
    };
    validateEditPipelineFields = (pipeline_name: string, description: string) => {
        const errors: PipelineEditErrorType = {};

        if (pipeline_name.length > 100) {
            errors.pipeline_name = "Pipeline name cannot be more than 100 characters.";
        }

        if (description.length > 300) {
            errors.description = "Pipeline description cannot exceed 300 characters.";
        }

        return errors;
    };
    editLevelsListAPI=async ()=>{
        const token = localStorage.getItem("token")
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.editLevelsListCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.levelsListApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }
    handleEditPipeline = (event: any) => {
        event.preventDefault()
        const { pipeline_name, description, associatedClinic, singlePipeline, selectedLevels, selectedTasks, selectedUsers } = this.state
        const pipelineErrors = this.validateEditPipelineFields(pipeline_name, description);

        if (Object.keys(pipelineErrors).length > 0) {
            this.setState({ editPipelineErrors: pipelineErrors });
            return;
        }
        let selectedLevelIds=selectedLevels.map((item) => item.attributes.id);
        let pipelineID= localStorage.getItem("pipelineID")

        const token = localStorage.getItem("token")
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const httpBody = {
            "pipeline": {
                "pipeline_name":pipeline_name ,
                "description": description,
                "status": "to_do",
                "permission_ids": selectedLevelIds
            }
        } 
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.editPipelineAPICallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createPipelineApiEndPoint+"/"+pipelineID
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChangeEditAutoComplete = (name: string) => (event: any, newValue: any) => {
        this.setState((prev) => {
            return {
                ...prev,
                [name]: newValue
            }
        });
    }
    handleEditClinicSelect = (clinic: string) => {
        this.setState({ associatedClinic: clinic })
    }
    // Customizable Area End
}
