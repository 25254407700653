import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { Box, Button as MuiButton, Typography as MuiTypography, TextField, IconButton, Modal, Divider, Checkbox, styled } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateTimeTable, PipelineType } from "./utils/generatePipeline";
import PaginationComponent from "./utils/PaginationComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingSpinner } from "./CreatePipeline.web";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A42", borderRadius: "20rem" }} />;
// Customizable Area End

import Cfpipelines1Controller, {
  Props,
  configJSON,
} from "./Cfpipelines1Controller";

export default class Cfpipelines1 extends Cfpipelines1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderColumnTypography = (flex: number, text: string | number,field: string) => {
    const { sortField, sortOrder } = this.state;
    let sortIcon = null;
    if (sortField === field) {
      sortIcon = sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;
    } else {
      sortIcon = <ArrowDropDownIcon />;
    }

    return (
      <MuiTypography variant="h6" data-test-id={"column-typography"+field} onClick={() => this.handleSortPipeline(field)} style={{ display: "flex", alignItems: "center", fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}{text == "Actions" ? "" : sortIcon}</MuiTypography>
    )
  }
  renderDatePicker = (text: string, value: Dayjs, onChangeText: string, minDate: Dayjs | null = null) => {
    return (
      <Box><MuiTypography style={{
        fontSize: '1.2rem', fontWeight: 400, color: '#1A4A42', marginBottom: "0.2rem"
      }}>{text}</MuiTypography>
        <DatePicker
          value={value}
          minDate={minDate}
          format="DD-MM-YYYY"
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                placeholder="Select Date"
                sx={{
                  fontSize: '1.4rem',
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    fontWeight: 400,
                    fontSize: "1.4rem",
                    borderRadius: "0.8rem",
                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '0.6rem 0 0.6rem 1rem',
                      fontSize: "1.4rem",

                    }
                  },
                }}
              />
            ),
          }}
          slotProps={{
            popper: {
              sx: {
                '& .MuiPaper-root': {
                  minWidth: '30rem',
                  border: '0.1rem solid #E2E8F0',
                  marginTop: '0.2rem',
                  borderRadius: '0.8rem'
                },
                '& .MuiTypography-root': {
                  fontSize: '1.8rem !important',
                },
                '& .MuiPickersArrowSwitcher-button': {
                  fontSize: '2rem !important',
                  '& .MuiSvgIcon-root': {
                    fontSize: '2rem !important',
                  },
                },
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontSize: '1.6rem'
                },
                '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                  width: '2rem',
                  height: '2rem'
                },
                '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                  border: 'none',
                  fontWeight: 700
                },

                '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                  fontSize: '1.4rem'
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  fontSize: '1.4rem !important',
                  fontWeight: 400
                },
              },
            },
            day: {
              sx: {
                fontSize: '1.4rem',
              },
            },
          }}
          onChange={(newValue) => this.handleDateChange(onChangeText, newValue)}
          data-test-id="date_picker"
        />
      </Box>
    )
  }

  renderFilterDateSection = (title: string, name: string, inputState: any, onChangeText: string, inputState2: any, onChangeText2: string) => {
    const { openRows, dates } = this.state
    const minDateForToPicker = dates.createdFrom ? dayjs(dates.createdFrom).add(1, 'day') : null;
    return (
      <Box style={{ marginBottom: '0.8rem' }}>
        <Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#3E3E3E", fontSize: '1.6rem' }}>{title}</MuiTypography>
          <IconButton data-test-id="down_up_arrow" onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
          </IconButton>
        </Box>
        {openRows[name] &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                paddingTop: '0',
                display: "flex",
                gap: "1rem"
              }}
            >
              {this.renderDatePicker("From", inputState, onChangeText)}
              {this.renderDatePicker("To", inputState2, onChangeText2, minDateForToPicker)}
            </Box>
          </LocalizationProvider>
        }
      </Box>
    )
  }
  renderRowTypography = (flex: number, text: string | number, row: PipelineType) => {
    return (
      <MuiTypography variant="h6" data-test-id="row-typography" style={{
        fontSize: '1.4rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 600, cursor: 'pointer', color: '#203447'
      }} onClick={() => this.handlePipelineNavigate(row)}>{text}</MuiTypography>
    )
  }
  
  renderCheckBox = (name: string, text: string, checkValue: boolean) => {

    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: "0.1rem solid #1A4A4233",
          borderRadius: "0.8rem",
          boxSizing: "border-box",
          flex: "1 1 calc(50% - 1rem)"

        }}
      >
        <Checkbox
          checked={checkValue}
          onChange={this.handleClinicChange}
          icon={icon}
          checkedIcon={checkedIcon}
          name={name}
        />
        <MuiTypography
          sx={{
            fontSize: "1.4rem",
            fontWeight: 400,
            color: "#0F172A"
          }}
        >
          {text}
        </MuiTypography>
      </Box>
    );
  }
  renderFilterSection = (title: string, name: string, data: any, handleChange: (event: any) => void) => {
    const { openRows } = this.state;
  
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#3E3E3E", fontSize: "1.6rem" }}>
            {title}
          </MuiTypography>
          <IconButton data-test-id={"arrows"+name} onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? (
              <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: "1.8rem" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: "1.8rem" }} />
            )}
          </IconButton>
        </Box>
        {openRows[name] && (
          <Box display="flex" sx={{ gap: "1rem", flexWrap: "wrap" }}>
            {Object.keys(data).map((key) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                sx={{
                  border: "0.1rem solid #1A4A4233",
                  borderRadius: "0.8rem",
                  boxSizing: "border-box",
                  flex: "1 1 calc(50% - 1rem)",
                }}
              >
                <Checkbox
                  data-test-id={"checkbox"+key}
                  checked={data[key]}
                  onChange={handleChange}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  name={key}
                />
                <MuiTypography sx={{ fontSize: "1.4rem", fontWeight: 400, color: "#0F172A" }}>
                  {key.replace(/^\w/, (c) => c.toUpperCase())}
                </MuiTypography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };
  renderClinicFilterSection = () => this.renderFilterSection("Associated Clinics", "clinics", this.state.clinics, this.handleClinicChange);
  renderPermissionFilterSection = () => this.renderFilterSection("Permission Levels", "permissions", this.state.levels, this.handleLevelChange)

  pipelineList = () => {
    const { currentPage, pageSize, filteredData } = this.state;
    const sortedRows = this.handleSortPipelineData()
    const paginatedRows = sortedRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, sortedRows.length);
    const totalResults = sortedRows.length;
    return <Box style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>
          Pipelines
        </MuiTypography>

        <Box display='flex' alignItems='center' gap={2.2}>
          <SearchBar testID="btnNav" handleSearchField={this.handleSearchPipeline} />
          <MuiButton onClick={this.handleCreatePipeline} sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.4rem 1.6rem',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            fontSize: '1.4rem',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#1B5C54',
              color: '#fff',
            }
          }}>
            Create Pipeline
          </MuiButton>
          <Box style={{ position: 'relative' }}>
            <FilterListIcon onClick={this.toggleFilterModal} style={{ ...iconStyle, cursor: "pointer", fontSize: "2rem" }} />
            {this.state.isFilterModal &&
                <Box
                  sx={filterModalStyle}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#3E3E3E", fontSize: '1.6rem' }}>Filters</MuiTypography>
                    <IconButton onClick={() => this.toggleFilterModal()} >
                      <CloseIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />
                    </IconButton>
                  </Box>
                  {this.renderFilterDateSection("Date Created", "created_at", this.state.dates.createdFrom, "createdFrom", this.state.dates.createdTo, "createdTo")}
                  {this.renderFilterDateSection("Last Edited", "updated_at", this.state.dates.editedFrom, "editedFrom", this.state.dates.editedTo, "editedTo")}
                  {this.renderClinicFilterSection()}
                  {this.renderPermissionFilterSection()}
                  <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <MuiButton onClick={this.resetFilters} variant="contained" sx={{
                      backgroundColor: '#1A4A421A',
                      color: '#1A4A4299',
                      padding: '0.8rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1.2rem',
                      width: '8rem',
                      '&:hover': {
                        backgroundColor: '#F5FEFD',
                      }
                    }}>
                      Reset
                    </MuiButton>
                    <MuiButton variant="contained" onClick={this.handleApplyPipelineFilter} sx={{
                      backgroundColor: '#1A4A42',
                      color: 'white',
                      padding: '0.8rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1.2rem',
                      width: '8rem',
                      '&:hover': {
                        backgroundColor: '#1B5C54',
                      }
                    }}>
                      Apply
                    </MuiButton>
                  </Box>
                </Box>
              }
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection='column' gap={1}>
        <Box display="flex" style={{ backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }}>
          {this.renderColumnTypography(3, 'Pipeline ID',"id")}
          {this.renderColumnTypography(5, 'Name',"name")}
          {this.renderColumnTypography(3, 'Date Created',"created_at")}
          {this.renderColumnTypography(3, 'Last Edited',"updated_at")}
          {this.renderColumnTypography(3, 'Associated Clinic',"associated_clinic")}
          {this.renderColumnTypography(1.5, 'Actions',"actions")}
        </Box>
        {paginatedRows.length > 0 ? paginatedRows.map(row => (
          <Box display='flex' key={row.id} style={{ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
            {this.renderRowTypography(3, row.pipeline_serial_id, row)}
            {this.renderRowTypography(5, row.pipeline_name, row)}
            {this.renderRowTypography(3, formatDateTimeTable(row.created_at), row)}
            {this.renderRowTypography(3, formatDateTimeTable(row.updated_at), row)}
            {this.renderRowTypography(3, row.clinic_name, row)}
            <Box style={{ flex: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton data-test-id="duplicate-btn-test" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() => this.handleDuplicateToggle(row)}>
                <ContentCopyIcon />
              </IconButton>
              <IconButton data-test-id="eye-btn-test" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() =>{this.handlePipelineNavigate(row)}}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Pipelines Found</MuiTypography></Box>}
      </Box>
      <PaginationComponent currentPageIndex={currentPage} requiredPageSize={pageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChange}/>     
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineListContainerStyle}>
        {/* <TopNav navigation={this.props.navigation} id="pipelines" handleSearchField={this.handleSearchPipeline} />
        <Divider sx={dividerStyle} /> */}
        {this.state.isLoading ?
                <LoadingSpinner />
                :
          this.pipelineList()}
        <Modal
          data-test-id="delete-pipeline-modal"
          open={this.state.isDeletePipelineModal}
          onClose={this.toggleDeleteModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Delete</MuiTypography>
              <IconButton onClick={this.toggleDeleteModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <MuiTypography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to delete pipeline: ${this.state.pipeline_name} ?`}</MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <MuiButton onClick={this.toggleDeleteModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                '&:hover': {
                  backgroundColor: '#1A4A421A',
                },
              }}>
                Cancel
              </MuiButton>
              <MuiButton  onClick={this.handleDeletePipeline} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                '&:hover': {
                  backgroundColor: '#1A4A42',
                },
              }}>
                Delete
              </MuiButton>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isPipelineDuplicateModal}
          onClose={this.toggleDuplicateModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Duplicate Pipeline</MuiTypography>
              <IconButton onClick={this.toggleDuplicateModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <MuiTypography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to duplicate pipeline: ${this.state.singlePiplineDuplicate.pipeline_name} ?`}</MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <MuiButton onClick={this.toggleDuplicateModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                fontSize: '1.2rem'
              }}>
                Cancel
              </MuiButton>
              <MuiButton onClick={this.handleDuplicatePipeline} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                fontSize: '1.2rem'
              }}>
                Duplicate
              </MuiButton>
            </Box>
          </Box>
        </Modal>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const SearchBar = ({
  handleSearchField,
}:any) => {
  const handleInputChange = (event: { target: { value: string; }; }) => {
    if (handleSearchField) {
      handleSearchField(event?.target?.value);
    }
  };

  return (
    <SearchContainer>
      <SearchInput
      testID={"search"}
        placeholder={"Search"}
        onChange={handleInputChange}
      />
      <SearchIconWrapper>
        <SearchIcon fontSize={"large"} />
      </SearchIconWrapper>
    </SearchContainer>
  );
};
const pipelineListContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
};
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.6rem",
  borderRadius: "0.6rem",
};

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width: '28rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 2,
}
const SearchContainer = styled("div")({
  position: "relative",
  minWidth: "50%",
  maxWidth: "600px",
  "@media (max-width: 600px)": {
    width: "100%"
  }
});

const SearchInput = styled("input")({
  width: "100%",
  padding: "1rem 1rem 1rem 4rem",
  borderRadius: "1rem",
  border: "0.1rem solid #ccc",
  fontSize: "1.6rem",
  outline: "none",
  '&::placeholder': {
    color: "#1A4A42",
    opacity: 1,
    fontWeight: "700"
  }
});

const SearchIconWrapper = styled("div")({
  position: "absolute",
  left: "1rem",
  top: "50%",
  transform: "translateY(-50%)",
  pointerEvents: "none",
  color: "#1A4A42",
});
// Customizable Area End
