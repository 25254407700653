// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface TriggerDetails {
  id: number;
  trigger_name: string;
  description: string;
  parameters: Parameter[];
  examples: Example[];
}

interface Parameter {
  parameter: string;
  parent_values: ParentValues;
  child_values: ChildValues;
}

interface ParentValues {
  parent_parameter: string;
  parent_parameter_content: string;
  parent_parameter_description: string;
}

interface ChildValues {
  child_parameter: string;
  child_parameter_content: string;
  child_parameter_description: string;
  child_parameter_types: ChildParameterType[];
}

interface ChildParameterType {
  heading: string;
  description: string;
  conditionData: Condition[];
}

interface Condition {
  condition: string;
  input: string;
}

interface Example {
  name: string;
  description: string;
  parameter_values: ParameterValues;
}

interface ParameterValues {
  parameter: string;
  parent_parameter: string;
  child_parameter: string;
  condition: string;
  input: string;
}
export interface TriggerData {
id:number,
trigger_name:string,
selection1:string,
value1:string,
selection2:string,
value2:string,
created_at:string,
updated_at:string,
details?:TriggerDetails,
  }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage: number,
  pageSize: number,
  rowData: TriggerData[],
  filteredData: TriggerData[],
  searchQuery: string
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TriggersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    triggerListCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 0,
      pageSize: 10,
      rowData: [],
      filteredData: [],
      searchQuery: '',
      isLoading:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     let errorResponse  =message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId===this.triggerListCallId) {
            this.handleTriggerListRes(responseJson, errorResponse);
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.triggerListAPI()
  }

  handleTriggerListRes=(responseJson:TriggerData[],errorRes: string)=>{
    if (responseJson) {
      let triggerDetails =[
  {
    id: 1,trigger_name: "Field is Changed",
        description: `Trigger activates when a specific field in the deal or the assigned user ID profile of the deal is changed. This is useful for tracking updates to fields like "Name, " "Date of Birth, " "Status, " or "Priority."`,
    parameters: [
        {
            parameter: "Parameter 1",
            parent_values: {
                parent_parameter: "Parent Parameter 1",parent_parameter_content: "*LIST OF ALL DB TABLES*",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "*ALL FIELD IDS IN SPECIFIED TABLE*",child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "If Child Parameter 1 = Text Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData: [{ condition: "CONTAINS", input: "FREE TEXT" },
                        { condition: "DOES NOT CONTAIN", input: "FREE TEXT" },{ condition: "IS EXACTLY", input: "FREE TEXT" },
                        { condition: "STARTS WITH", input: "FREE TEXT" },
                        { condition: "ENDS WITH", input: "FREE TEXT" },{ condition: "ANY", input: "-" }
                        ]
                    },

                    {
                        heading: "If Child Parameter 1 = Date Field",
                        description: "If child parameter is a date field, we can apply the above conditions, and the input can be some date.",
                        conditionData: [
                            { condition: "DATE IS", input: "DD/MM/YYYY" },
                        { condition: "DATE IS BEFORE", input: "DD/MM/YYYY" },
                        { condition: "DATE IS ON OR BEFORE", input: "DD/MM/YYYY" },
                        { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY + DD/MM/YYYY" },
                        { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY + DD/MM/YYYY" },
                        { condition: "DATE IS X FROM NOW", input: "MINUTES, HOURS,DAYS, MONTHS" },{ condition: "ANY", input: "-" }
                        ]},

                    {
                        heading: "If Child Parameter 1 = Number Field",
                        description: "If child parameter is a number field, we can apply the above conditions, and the input can be some number.",
                        conditionData: [
                            { condition: "EQUAL TO", input: "01234" },
                         { condition: "NOT EQUAL TO", input: "01234" },
                        { condition: "GREATER THAN", input: "01234" },
                          { condition: "GREATER THAN OR EQUAL TO", input: "01234" },
                        { condition: "LESS THAN", input: "01234" },
                        { condition: "LESS THAN OR EQUAL TO", input: "01234" },
                         { condition: "IS BETWEEN", input: "01234" },
                        { condition: "ANY", input: "-" }
                        ]
                    },
                ]
            }
        },
    ],
    examples:[
        {
            name:"Example 1",
            description:`Task Deal Type-  When the status of a task is changed from "Incomplete" to "Complete," the CRM automatically sends a notification to the assigned user, prompting them to proceed with the next steps.`,
            parameter_values: {
                parameter:"Parameter 1",
                parent_parameter: "PATIENT DATABASE",
                child_parameter: "EMAIL ADDRESS",
                condition: "ANY",
                input:"-"
            },
        },
        {
            description:`When the Date of Birth of a client is changed the CRM moves the user’s deal into a new stage of the ‘Birthday’ pipeline and sends a task for a CC to re-verify the user’s insurance information using the new DOB`,
            name:"Example 2",
            parameter_values: {
                parameter:"Parameter 1",
                parent_parameter: "PROVIDER DATABASE",
                child_parameter: "DATE OF BIRTH",
                condition: "DATE IS",
                input:"TODAY"
            },
        }
    ]
},

{
  id: 2,
      description: `This trigger activates when a session is booked in the platform for a user or by a user. It is used to automate processes such as sending confirmation emails, scheduling reminders, or preparing pre-session materials.`,
      trigger_name: "Session Is Booked",

    
  examples:[
      {
          name:"Example 1",
          description:`A client books an onboarding session. The CRM moves the user based deal into the ‘appointment booked’ stage and sends a confirmation email to the client with details about the session.`,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "SESION DB TABLE",child_parameter: "SESSION NUMBER",
              condition: "LESS THAN",
              input:"NUMBER"
          },
      },
  ],
  parameters: [
    {
        parameter: "Parameter 1",
        parent_values: {
            parent_parameter: "Parent Parameter 1",
            parent_parameter_content: "SESSION DB TABLE",
            parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",
            child_parameter_content: "SESSION NUMBER",
            child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_types: [
                {
                    heading: "Child Parameter 1 ALWAYS = Number Field",
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData: [{ condition: "EQUAL TO", input: "NUMBER" },
                      { condition: "NOT EQUAL TO", input: "NUMBER" },
                      { condition: "GREATER THAN", input: "NUMBER" },{ condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                      { condition: "LESS THAN", input: "NUMBER" },
                      { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                      { condition: "IS BETWEEN", input: "NUMBER" },
                      { condition: "ANY", input: "-" }
                      ]
                },
            ]
        }
    },
    {
      parameter: "Parameter 2",
      parent_values: {
          parent_parameter_content: "SESSION DB TABLE",
          parent_parameter: "Parent Parameter 1",
          parent_parameter_description: "This parameter allows the user to search for any database on the platform."
      },
      child_values: {
          child_parameter_content: "SESSION NUMBER",
          child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
          child_parameter_types: [
              {
                  heading: "Child Parameter 1 ALWAYS = Number Field",
                  conditionData: [
                    { condition: "DATE IS", input: "DD/MM/YYYY"
                     },
                    { condition: "DATE IS BEFORE",
                         input: "DD/MM/YYYY" },
                    { condition: "DATE IS ON OR BEFORE",
                     input: "DD/MM/YYYY" },
                    { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                    { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                    { condition: "DATE IS X FROM NOW", input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
                    { condition: "ANY", input: "-" }
                    ],
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text."
              },
          ],
          child_parameter: "Child Parameter 1",
      }
  },
],
},



{
  id: 3,
      trigger_name: "Session Occurs",
      description: `This trigger activates once a scheduled session has taken place. It can automate follow-up processes, update records, or start workflows tied to the session's outcome.`,
  parameters: [{parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",
              parent_parameter_content: "SESSION DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },child_values: {child_parameter_content: "SESSION NUMBER",
              child_parameter: "Child Parameter 1",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                    conditionData: [
                        { condition: "EQUAL TO", input: "NUMBER" },{ condition: "NOT EQUAL TO", input: "NUMBER" },
                        { condition: "GREATER THAN", input: "NUMBER" },
                        { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },{ condition: "LESS THAN", input: "NUMBER" },
                        { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                        { condition: "IS BETWEEN", input: "NUMBER" }, { condition: "ANY", input: "-" }
                        ],
                      heading: "Child Parameter 1 ALWAYS = Number Field",

                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",   
                  },
              ]
          }
      },
      {
        parameter: "Parameter 2",
        parent_values: {
            parent_parameter_content: "SESSION DB TABLE",
            parent_parameter: "Parent Parameter 1",
            parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",
            child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_content: "SESSION NUMBER",
            child_parameter_types: [
                {heading: "Child Parameter 1 ALWAYS = Number Field",
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData:
                    [{ condition: "DATE IS", input: "DD/MM/YYYY" },
                      { condition: "DATE IS BEFORE", input: "DD/MM/YYYY" },
                      { condition: "DATE IS ON OR BEFORE",
                         input: "DD/MM/YYYY" },
                      { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                      { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                      { condition: "DATE IS X FROM NOW", 
                        input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
                      { condition: "ANY", input: "-" }
                      ]
                },
            ]
        }
    },
  ],
  examples:[
      {
          name:"Example 1",
          description:`After a client completes their initial session
AND If the client does not have a follow up session booked
The CRM moves the user based deal into the ‘needs follow up session’ stage and sends an email to the client prompting them to book a follow up.`,
          parameter_values: {
              parameter:"Parameter 1",parent_parameter: "SESION DB TABLE",
              child_parameter: "SESSION NUMBER", condition: "GREATER THAN",
              input:"NUMBER"
          },
      }]},

{
  id: 4,
      trigger_name: "Session Is Missed/Rescheduled",
      description: `This trigger activates when a session is marked by a user, or automatically from the system, as missed or rescheduled. It ensures proper follow-up actions are taken, including notifications, rescheduling, or billing adjustments.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",parent_parameter_content: "SESSION DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter_content: "SESSION NUMBER",child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter: "Child Parameter 1",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData: [
                        { condition: "EQUAL TO", input: "NUMBER" },{ condition: "NOT EQUAL TO", input: "NUMBER" },{ condition: "GREATER THAN", input: "NUMBER" },
                        { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                        { condition: "LESS THAN", input: "NUMBER" },{ condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },{ condition: "IS BETWEEN", input: "NUMBER" },
                        { condition: "ANY", input: "-" }
                        ]
                  }]
          }
      },
      {
        parameter: "Parameter 2",
        parent_values: {
            parent_parameter: "Parent Parameter 1",
            parent_parameter_content: "SESSION DB TABLE",
            parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",
            child_parameter_content: "SESSION NUMBER",
            child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_types: [
                {
                    heading: "Child Parameter 1 ALWAYS = Number Field",
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData: [{ condition: "DATE IS", input: "DD/MM/YYYY" },{ condition: "DATE IS BEFORE", input: "DD/MM/YYYY" },
                      { condition: "DATE IS ON OR BEFORE", input: "DD/MM/YYYY" },
                      { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                      { condition: "DATE IS X FROM NOW", input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
    { condition: "ANY", input: "-" }
                      ]
                },
            ]
        }
    },
  ],
  examples:[
      {
          name:"Example 1",
          description:`A client does not show up to an onboarding session. The system marks the session as missed
Then the CRM moves the client’s deal to the "Missed Appointment" stage, and charges a no-show fee.`,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "SESION DB TABLE",
              child_parameter: "SESSION NUMBER",
              condition: "LESS THAN", input:"NUMBER"
          },
      },
  ]
},

{
  id: 5,trigger_name: "Appointment Is",
      description: `This trigger listens out for any field on a particular database that has changed. In order to use this trigger you must specify which database field you wish to look out for, and then use conditional operators to define your criteria.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",
              parent_parameter_content: "SESSION DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter: "Child Parameter 1",
              child_parameter_content: "SESSION NUMBER",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData: 
                      [{ condition: "EQUAL TO", input: "NUMBER" },{ condition: "NOT EQUAL TO", input: "NUMBER" },{ condition: "GREATER THAN", input: "NUMBER" },{ condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },{ condition: "LESS THAN", input: "NUMBER" },
                        { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },{ condition: "IS BETWEEN", input: "NUMBER" },{ condition: "ANY", input: "-" }
                        ]
                }
              ]
          }
      },
      {
        parameter: "Parameter 2",
        parent_values: {
            parent_parameter: "Parent Parameter 1",
            parent_parameter_content: "SESSION DB TABLE",
            parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",
            child_parameter_content: "SESSION NUMBER",
            child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_types: [
                {heading: "Child Parameter 1 ALWAYS = Number Field",
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData: [{ condition: "DATE IS", input: "DD/MM/YYYY" },
                      { condition: "DATE IS BEFORE", input: "DD/MM/YYYY" },
                      { condition: "DATE IS ON OR BEFORE", input: "DD/MM/YYYY" },
                      { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                      { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                      { condition: "DATE IS X FROM NOW", input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
                      { condition: "ANY", input: "-" }
                      ]
                },
            ]
        }
    },
  ],
  examples:[
      { name:"Example 1",
          description:`Here we are checking all emails in the patient database, so that if any patient changes their email, we will then perform a set of actions.`,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "SESION DB TABLE",
              child_parameter: "SESSION DATE",
              condition: "DATE IS BEFORE",
              input:"DD/MM/YYYY"
          },
      },
  ]
},

{
  id: 6,
      trigger_name: "Deal is Moved",
      description: `This trigger activates when a deal is moved to a new stage in a pipeline. It helps streamline processes by triggering notifications, assigning tasks, or updating deal records.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {parent_parameter: "Parent Parameter 1",
              parent_parameter_content: "DEAL DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter: "Child Parameter 1",child_parameter_content: "STAGE ID",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData: [{ condition: "FROM", input: "NUMBER" },
                        { condition: "TO", input: "NUMBER" },
                        { condition: "ANY", input: "-" }
                        ]
                  }
              ]}
      },
  ],
  examples:[
      {
          name:"Example 1",
          description:`When a deal is moved to the "Qualified" stage, the CRM assigns a follow-up task to the team responsible for the next steps.`,
          parameter_values: {parameter:"Parameter 1",
              parent_parameter: "DEAL DB TABLE",
              child_parameter: "STAGE ID",
              condition: "FROM",
              input:"NUMBER"
          },
      },
  ]
},

{
  id: 7,trigger_name: "Deal is Created",
      description: `This trigger activates when a new deal is created in the CRM. It is often used to initiate workflows or assign deals to users based on predefined conditions.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",
              parent_parameter_content: "DEAL DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter: "Child Parameter 1",
              child_parameter_content: "PIPELINE ID",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData: [{ condition: "EQUAL TO", input: "NUMBER" },
                        { condition: "NOT EQUAL TO", 
                            input: "NUMBER" },
                        { condition: "GREATER THAN",
                             input: "NUMBER" },{ condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                        { condition: "LESS THAN", input: "NUMBER" },
                        { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },{ condition: "IS BETWEEN", input: "NUMBER" },
                        { condition: "ANY", input: "-" }]
                  },
              ]
          }
      },
      {
        parameter: "Parameter 2",
        parent_values: {
            parent_parameter: "Parent Parameter 1",
            parent_parameter_content: "DEAL DB TABLE",parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",
            child_parameter_content: "STAGE ID",child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_types: [
                {
                    heading: "Child Parameter 1 ALWAYS = Number Field",description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData: [{ condition: "EQUAL TO",
                         input: "NUMBER" },
                      { condition: "NOT EQUAL TO", input: "NUMBER" },
                      { condition: "GREATER THAN", input: "NUMBER" },
                      { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                      { condition: "LESS THAN", input: "NUMBER" },
                      { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                      { condition: "IS BETWEEN", input: "NUMBER" },
                      { condition: "ANY", 
                        input: "-" }
                      ]
                },
            ]
        }
    },
    {
      parameter: "Parameter 3",
      parent_values: {
          parent_parameter: "Parent Parameter 1",
          parent_parameter_content: "DEAL DB TABLE",
parent_parameter_description: "This parameter allows the user to search for any database on the platform."
      },
      child_values: {
          child_parameter: "Child Parameter 1",
          child_parameter_content: "CREATED ON",
          child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
          child_parameter_types: [
              {
    heading: "Child Parameter 1 ALWAYS = Number Field",
            description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                  conditionData: [{ 
                    condition: "DATE IS", input: "DD/MM/YYYY" },
                    { condition: "DATE IS BEFORE", input: "DD/MM/YYYY" },
                    { condition: "DATE IS ON OR BEFORE", input: "DD/MM/YYYY" },
                    { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                    { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                    { condition: "DATE IS X FROM NOW", input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
                    { condition: "ANY", input: "-" }
                    ]
              },
          ]
      }
  },
  ],
  examples:[
      {
          name:"Example 1",description:`When a new deal is created in the ‘New Client’ pipeline, the CRM automatically assigns it to CC team, and gives it high priority status `,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "DEAL DB TABLE",child_parameter: "STAGE ID",
              condition: "IN BETWEEN",
              input:"NUMBER"
          },
      },
  ]
},


{
    id: 8,
        trigger_name: "Deal is Assigned",description: `This trigger activates when a new deal is created in the CRM. It is often used to initiate workflows or assign deals to users based on predefined conditions.`,
    parameters: [
        {
            parameter: "Parameter 1",parent_values: {
                parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "DEAL DB TABLE",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "ASSIGNED ID",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [{
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData: [{ condition: "INDIVIDUAL", input: "SEARCH OR DROPDOWN WHICH RETURNS CORRECT USER AND THEIR ID" },
                          { condition: "TASK GROUP", input: "SEARCH OR DROPDOWN WHICH RETURNS CORRECT USER AND THEIR ID" },
                          { condition: "ANY", input: "-" },
                          ]
                    },
                ]
            }
        },
      
    ],
    examples:[
        {
            name:"Example 1",
            description:`When a new client signs up and is assigned to the CC when the enter the ‘New client’ pipeline , the CRM sends a notification AND an emailto the assigned user:"Deal [Deal Name] has been assigned to you in the [Pipeline Name] pipeline. Please review the details and take the necessary next steps."`,
            parameter_values: { parameter:"Parameter 1",
                parent_parameter: "DEAL DB TABLE",
                child_parameter: "ASSIGNED ID",
                condition: "ANY",input:"-"
            },
        },
    ]
  },

{
  id: 9,
      trigger_name: "New User is Created",
      description: `This trigger activates when a new user (e.g., a lead, customer, or team member) is added to the system. It can automate onboarding workflows or send notifications. It is the starting point for getting the user deal into the CRM.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",
 parent_parameter_content: "LIST OF ALL DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter: "Child Parameter 1",
              child_parameter_content: "USER TYPE",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData: [{ condition: "PATIENT", input: "NUMBER" },
                        { condition: "PROVIDER", input: "NUMBER" },
        { condition: "CARE CORDINATOR", input: "NUMBER" },
            { condition: "CLINIC", input: "NUMBER" },
                        { condition: "SUPER CLINIC", input: "NUMBER" },
                        { condition: "API CLIENT", input: "NUMBER" },
                        { condition: "ANY", input: "-" }]
                  },
              ]
          }
      },
      {
        parameter: "Parameter 2",
        parent_values: {
            parent_parameter: "Parent Parameter 1",
            parent_parameter_content: "ORGANISATION TABLE",
            parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",
            child_parameter_content: "ORGANISATION ID",
            child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_types: [
                {
                    heading: "Child Parameter 1 ALWAYS = Number Field",
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData: [ { condition: "EQUAL TO", input: "NUMBER" },
                      ]
                },
            ]
        }
    },
    
  ],
  examples:[
      {
          name:"Example 1",
          description:`When a new provider fills out the onboarding form a temporary account is created and this trigger is activates
Then the CRM creates a new user based deal in the New Provider Pipeline`,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "ORGANISATION TABLE",
              child_parameter: "ORGANISATION ID",
              condition: "EQUAL TO",
              input:"NUMBER"
          },
      },
  ]
},

{
  id: 10,
    trigger_name: "Deal was Changed",
      description: `This trigger tracks when a deal was last updated in the CRM. It tracks the date the deal was changed. It is commonly used to automate tasks or notifications based on the type of change.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",
              parent_parameter_content: "DEAL DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter: "Child Parameter 1",
              child_parameter_content: "LAST EDITED",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData: [{ condition: "DATE IS", input: "DD/MM/YYYY" },
                        { condition: "DATE IS BEFORE", input: "DD/MM/YYYY" }, { condition: "DATE IS ON OR BEFORE", input: "DD/MM/YYYY" },
                        { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                        { condition: "DATE IS NOT BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },{ condition: "DATE IS X FROM NOW", input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
                        { condition: "ANY", input: "-" }
                        ]
                  },
              ]
          }
      },
  ],
  examples:[
      {
          name:"Example 1",
          description:`If a client deal was last changed in 2023 and the client does not have any future sessions booked, 
Move the deal to ‘archived’ stage`,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "PATIENT DATABASE",
              child_parameter: "LAST EDITED",
              condition: "DATE IS OR AFTER",
              input:"DD/MM/YYYY"
          },
      },
  ]
},
{
      trigger_name: "Deal is Located",
      id: 11,
      description: `This trigger checks the pipeline and stage where a deal is currently located and performs actions based on the location.`,
  parameters: [
      { parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",
              parent_parameter_content: "DEAL DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter: "Child Parameter 1",
              child_parameter_content: "PIPELINE ID",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
            conditionData:[{
         condition: "EQUAL TO", input: "NUMBER" },
                        { condition: "NOT EQUAL TO", input: "NUMBER" },
                        { condition: "GREATER THAN", input: "NUMBER" },
                        { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                        { condition: "LESS THAN", input: "NUMBER" },
                        { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                        { condition: "IS BETWEEN", input: "NUMBER" },
                        { condition: "ANY", input: "-" }
                        ]
                  },
              ]
          }
      },
      {
        parameter: "Parameter 2",
        parent_values: {
            parent_parameter: "Parent Parameter 1",parent_parameter_content: "DEAL DB TABLE",
            parent_parameter_description: "This parameter allows the user to search for any database on the platform."
        },
        child_values: {
            child_parameter: "Child Parameter 1",child_parameter_content: "STAGE ID",
            child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
            child_parameter_types: [
                {
                    heading: "Child Parameter 1 ALWAYS = Number Field",
                    description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                    conditionData:[{ condition: "EQUAL TO", input: "NUMBER" },
                      { condition: "NOT EQUAL TO",
                         input: "NUMBER" },
                      { condition: "GREATER THAN", input: "NUMBER" },
                      { condition: "GREATER THAN OR EQUAL TO",
                         input: "NUMBER" },
                      { condition: "LESS THAN", input: "NUMBER" },
                      { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                      { condition: "IS BETWEEN", input: "NUMBER" },{ condition: "ANY", input: "-" }
                      ]
                },
            ]
        }
    },
  ],
  examples:[
      {
          description:`If a deal is located in the ‘Had an Emergency’ stage of the ‘Emergency’ pipeline and the most recent call of the user was inbound missed
Set as high priority and assign that deal to the CC manager`,
name:"Example 1",
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "DEAL DB TABLE",
              child_parameter: "STAGE ID",
              condition: "LESS THAN",
              input:"NUMBER"
          },
      },
  ]
},

{
  id: 12,
      trigger_name: "Deal Due Date",
      description: `This trigger activates based on the due date of a deal, such as when it is overdue or approaching. It ensures timely follow-ups or escalations.`,
  parameters: [
      {
          parameter: "Parameter 1",
          parent_values: {
              parent_parameter: "Parent Parameter 1",parent_parameter_content: "DEAL DB TABLE",
              parent_parameter_description: "This parameter allows the user to search for any database on the platform."
          },
          child_values: {
              child_parameter_content: "DUE DATE",
              child_parameter: "Child Parameter 1",
              child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
              child_parameter_types: [
                  {
                      heading: "Child Parameter 1 ALWAYS = Number Field",
                      description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                      conditionData:[{ condition: "DATE IS", input: "DD/MM/YYYY" },
                        { condition: "DATE IS BEFORE",
                             input: "DD/MM/YYYY" },{ condition: "DATE IS ON OR BEFORE", input: "DD/MM/YYYY" },
                        { condition: "DATE IS BETWEEN", input: "DD/MM/YYYY - DD/MM/YYYY" },
                        { condition: "DATE IS NOT BETWEEN",
                             input: "DD/MM/YYYY - DD/MM/YYYY" },
                        { condition: "DATE IS X FROM NOW", input: "DURATION(MINUTES, HOURS,DAYS, MONTHS)" },
                        { condition: "ANY", input: "-" }
                        ]
                  },
              ]
          }
      },
      
  ],
  examples:[
      {
          name:"Example 1",
          description:`Three days before a deal's due date, the CRM sends a reminder to the assigned user:The deal [Deal Name] is due on [Due Date]. Please take the necessary actions.`,
          parameter_values: {
              parameter:"Parameter 1",
              parent_parameter: "DEAL DB TABLE",
              child_parameter: "DUE DATE",
              condition: "DATE IS X FROM NOW", input:"DURATION(MINUTES, HOURS,DAYS, MONTHS)"
          },
      }
  ]
},

{
            id: 13,
        trigger_name: "SMS Inbound",
        description: `This trigger activates when an SMS containing specific keywords or phrases is received.`,
    parameters: [
        {
            parameter: "Parameter 1",
            parent_values: { parent_parameter: "Parent Parameter 1",parent_parameter_content: "INBOX",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData:[{ condition: "CONTAINS", input: "FREE TEXT" },
                            { condition: "DOES NOT CONTAIN", input: "FREE TEXT" },
                            { condition: "IS EXACTLY", input: "FREE TEXT" },
                            { condition: "STARTS WITH", input: "FREE TEXT" },
                            { condition: "ENDS WITH", input: "FREE TEXT" },
                            { condition: "ANY", input: "-" }
                            ]
                    },
                ],
                child_parameter_content: "MESSAGE CONTENT"
            }
        },
        {
            parameter: "Parameter 2",
            parent_values: {
                parent_parameter_content: "INBOX",
                parent_parameter: "Parent Parameter 1",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter_content: "INBOX ID",
                child_parameter: "Child Parameter 1",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData: [{ condition: "EQUAL TO", input: "NUMBER" },
                          { condition: "NOT EQUAL TO",
                             input: "NUMBER" },
                          {
                             condition: "GREATER THAN", input: "NUMBER" },
                          { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                          { 
                            condition: "LESS THAN", input: "NUMBER" },
                          { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                          { condition: "IS BETWEEN", input: "NUMBER" },
                          {
                             condition: "ANY", input: "-" }
                          ]
                    },
                ]
            }
        },
        
        
    ],
    examples:[
        {
            name:"Example 1",
            description:`If an SMS contains the keyword "Help," the CRM automatically responds with:"Thank you for reaching out. We have received your message and will respond shortly. For urgent matters, please call [Phone Number].`,
            parameter_values: {
                parameter:"Parameter 1",
                parent_parameter: "INBOX",
                child_parameter: "INBOX ID",
                condition: "LESS THAN",
                input:"NUMBER"
            },
        },
    ]
  },
  
  {
    id: 14,
        trigger_name: "MSG Inbound",
        description: `This trigger activates when a message (e.g., from chat) contains certain keywords or phrases.`,
    parameters: [
        {
            parameter: "Parameter 1",
            parent_values: {
                parent_parameter: "Parent Parameter 1",
     parent_parameter_content: "INBOX",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            }, child_values: {
                child_parameter: "Child Parameter 1",child_parameter_content: "MESSAGE CONTENT",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [{
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        conditionData:[{ condition: "CONTAINS", input: "FREE TEXT" },
                            { condition: "DOES NOT CONTAIN", input: "FREE TEXT" },
                            { condition: "IS EXACTLY", input: "FREE TEXT" },{ condition: "STARTS WITH", input: "FREE TEXT" },
                            { condition: "ENDS WITH", input: "FREE TEXT" },
                            { condition: "ANY", input: "-" }
                            ]
                    },
                ]
            }
        },
        {
            parameter: "Parameter 2",parent_values: {
                parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "INBOX",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "INBOX ID", child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData: [{ condition: "EQUAL TO", input: "NUMBER" },
                          { condition: "NOT EQUAL TO", input: "NUMBER" },{ condition: "GREATER THAN", input: "NUMBER" },
                          { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER"

                           },
                          { condition: "LESS THAN",
                            input: "NUMBER" },
                          { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                          { condition: "IS BETWEEN",
                             input: "NUMBER" },
                          { condition: "ANY", input: "-" }
                          ]
                    },
                ]
            }
        },
        
        
    ],
    examples:[
        {
            name:"Example 1",
            description:`If the message contains "emergency" and is not sent to the sales team, the CRM creates a task to notify a sales representative.`,
            parameter_values: {
                parent_parameter: "INBOX",
                child_parameter: "INBOX ID",
                condition: "LESS THAN",
                input:"NUMBER",
                parameter:"Parameter 1"
            },
        },
    ]
  },


  {
    id: 15,
        trigger_name: "Email Inbound",
        description: `This trigger scans incoming emails for keywords or patterns and performs actions based on the content.`,
    parameters: [
        {
            parameter: "Parameter 1",
            parent_values: {
                parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "INBOX",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "MESSAGE CONTENT",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        conditionData:[{ condition: "CONTAINS", input: "FREE TEXT" },
                            { condition: "DOES NOT CONTAIN", input: "FREE TEXT" },
                            { condition: "IS EXACTLY",
 input: "FREE TEXT" },
                            { condition: "STARTS WITH", input: "FREE TEXT" },
                            { condition: "ENDS WITH", input: "FREE TEXT" },
                            { condition: "ANY", input: "-" }
                            ],
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        
                    },
                ]
            }
        },
        {
            parameter: "Parameter 2",
            parent_values: {
                parent_parameter: "Parent Parameter 1",
        parent_parameter_content: "INBOX",
parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "INBOX ID",
    child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        conditionData: [{ condition: "EQUAL TO", input: "NUMBER" },
                          { condition: "NOT EQUAL TO", input: "NUMBER" },
                          { condition: "GREATER THAN", input: "NUMBER" },
                          { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                          { condition: "LESS THAN", input: "NUMBER" },
                          { condition: "LESS THAN OR EQUAL TO",
                             input: "NUMBER" },
                          { condition: "IS BETWEEN", input: "NUMBER" },
                          { condition: "ANY",
                             input: "-" }
                          ]
                    },
                ]
            }
        },
        
        
    ],
    examples:[
        {
            name:"Example 1",
            description:`If a user emails, "I'd like to book an appointment," but does not send it to care@totallife.com, the CRM alerts the sales team.`,
            parameter_values: { parameter:"Parameter 1",
                parent_parameter: "INBOX",
                child_parameter: "INBOX ID",
                condition: "GREATER THAN OR EQUAL TO",
                input:"NUMBER"
            },
        }
    ]
  },
  {
    id: 16,
        trigger_name: "Voicemail Inbound",
        description: `This trigger activates when a voicemail contains specific keywords in its transcript.`,
    parameters: [
        {parent_values: { parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "INBOX",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            parameter: "Parameter 1",
            child_values: {child_parameter: "Child Parameter 1",
                child_parameter_content: "MESSAGE CONTENT", child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData:[{ condition: "CONTAINS", input: "FREE TEXT" },
                            { condition: "DOES NOT CONTAIN", input: "FREE TEXT" },
                            { condition: "IS EXACTLY", input: "FREE TEXT" },{ condition: "STARTS WITH", input: "FREE TEXT" },
                            { condition: "ENDS WITH", input: "FREE TEXT" },
                            { condition: "ANY", input: "-" }]
}
                ]
            }
        },
        {
            parameter: "Parameter 2",
            parent_values: {
                parent_parameter: "Parent Parameter 1", parent_parameter_content: "INBOX",
     parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "INBOX ID",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData: [{ condition: "EQUAL TO", input: "NUMBER" },
                          { condition: "NOT EQUAL TO", input: "NUMBER" },
                          { condition: "GREATER THAN", input: "NUMBER" }, { condition: "GREATER THAN OR EQUAL TO", input: "NUMBER" },
                          { condition: "LESS THAN",
                             input: "NUMBER" },
                          { condition: "LESS THAN OR EQUAL TO", input: "NUMBER" },
                          { condition: "IS BETWEEN", input: "NUMBER" },
                          { condition: "ANY", input: "-" }
                          ]
                    },
                ]
            }
        },
        
        
    ],
    examples:[
        {
            name:"Example 1",
            description:
            `If a voicemail contains "emergency," the CRM immediately alerts the appropriate team.`,
            parameter_values: {
                parameter:"Parameter 1",
                parent_parameter: "INBOX",
                child_parameter: "INBOX ID",
                condition: "NOT EQUAL TO",
                input:"NUMBER"
            },
        },
    ]
  },
  {
    id: 17,
        trigger_name: "Call Status Is",description: `This trigger tracks the status of inbound and outbound calls, enabling targeted actions based on outcomes.`,
    parameters: [
        {
            parameter: "Parameter 1",parent_values: {
                parent_parameter_content: "CALL LOG",
                parent_parameter: "Parent Parameter 1",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "USER ID",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
 child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
conditionData:[{ condition: "EQUAL TO", input: "MOST RECENT" },
                            { condition: "EQUAL TO", input: "FIRST CALL" },
                            { condition: "EQUAL TO", input: "SPECIFIC CALL #" },
                            { condition: "EQUAL TO", 
                                input: "ALL CALLS" },
                            { condition: "EQUAL TO", input: "ANY" },
                            ]
                    },
                ]
            }
        },
        {
            parameter: "Parameter 2",
            parent_values: {
                parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "CALL LOG",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {child_parameter: "Child Parameter 1",
                child_parameter_content: "CALL STATUS",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData: [{ condition: "EQUAL TO", input: "INBOUND MISSED" },
                          { condition: "EQUAL TO", input: "INBOUND OR OUTBOUND CONNECTED" },
                          { condition: "EQUAL TO", input: "OUTBOUND CLIENT VOICEMAIL" },
                          ]
                    },
                ]
            }
        },
        
        
    ],
    examples:[
        {name:"Example 1",
            description:`If a patient’s most recent call is "missed inbound," the CRM schedules a callback.`,
            parameter_values: {
                parameter:"Parameter 1",
                parent_parameter: "CALL LOG",child_parameter: "USER ID",
                condition: "EQUAL TO",
                input:"ALL CALLS"
            },
        },
    ]
  },
  {
    id: 18,
        trigger_name: "Time Zone Is",
 description: `This trigger targets users’ time zones to perform actions at localized times.`,
    parameters: [
        {
            parent_values: {
                parent_parameter_content: "LIST OF ALL PROFILES DB TABLES",
                parent_parameter: "Parent Parameter 1",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            parameter: "Parameter 1",
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "USER TIME ZONE",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData:[{ condition: "TIME SZONE IS", input: "TIME ZONE (UTC)" },
                            { condition: "TIME ZONE IS NOT", input: "FIRST CALL" },
                            { condition: "ANY", input: "-" },
                            ]
                    },
                ]
            }
        },
        
    ],
    examples:[
        {
            name:"Example 1",
            description:`If a patient’s most recent call is "missed inbound," the CRM schedules a callback.`,
            parameter_values: {
                child_parameter: "USER TIME ZONE",
                condition: "TIME ZONE IS",
                input:"TIME ZONE UTC",
                parameter:"Parameter 1",
                parent_parameter: "LIST OF ALL PROFILES DB TABLES",
            },
        },
    ]
  },

  {
    id: 19,
        trigger_name: "Forms Are",
        description: `This trigger activates based on the completion status of forms.`,
    parameters:
 [
        {
            parameter: "Parameter 1",
            parent_values: { parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "LIST OF ALL FORM DB TABLES",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",
                child_parameter_content: "FORM ID",
                child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",child_parameter_types: [
                    {heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData:[{ condition: "SELECT FROM", input: "SAERCH OR DROPDOWN WHICH RETURNS CORRECT FORM AND ITS FORM ID" },
                            { condition: "TIME ZONE IS NOT", input: "FIRST CALL" },
                            { condition: "ANY", input: "-" }, ]
                    }
                ]
            }
        },
        {
            parameter: "Parameter 1",
            parent_values: {
                parent_parameter: "Parent Parameter 1",
                parent_parameter_content: "SPECIFIC FORM DB TABLE",
                parent_parameter_description: "This parameter allows the user to search for any database on the platform."
            },
            child_values: {
                child_parameter: "Child Parameter 1",child_parameter_content: "FORM STATUS", child_parameter_description: "This child parameter allows the user to select a specific database field from the database the chose in parameter 1.",
                child_parameter_types: [
                    {
                        heading: "Child Parameter 1 ALWAYS = Number Field",
                        description: "If child parameter is a text field, we can apply the above conditions, and the input can be some text.",
                        conditionData:[{ condition: "EQUAL TO", input: "COMPLETE" },
                            { condition: "INCOMPLETE", input: "INCOMPLETE" },]
                    },
                ]
            }
        },
    ],
    examples:[
        {
            description:`If a patient’s most recent call is "missed inbound," the CRM schedules a callback.`,
            parameter_values: {
                parameter:"Parameter 1",
                parent_parameter: "LIST OF ALL FORM DB TABLES",
                child_parameter: "FORM ID",
                condition: "ANY",
                input:"-"
            },
            name:"Example 1"
        },
    ]
  }]


let triggersList: TriggerData[] = responseJson;

triggersList.forEach((trigger) => {
  const matchingTriggerDetails = triggerDetails.find(
    (details) => details.trigger_name === trigger.trigger_name
  );

  if (matchingTriggerDetails) {
    trigger.details = matchingTriggerDetails; 
    }
});

this.setState({
  isLoading: false,
  filteredData: triggersList, 
  rowData: triggersList
});

    }
  }

  triggerListAPI=async ()=>{
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.triggerListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.triggerListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value - 1 });
  };

  handleTriggerNavigate = (trigger: TriggerData) => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate('TriggerDetail', { trigger: trigger.id })
    }
  }

  handleSearchTrigger = (query: string) => {
    this.setState({ searchQuery: query });
    const { rowData } = this.state
    if (query === '') {
      this.setState({ filteredData: rowData });
    }
    else {
      const filtered = rowData.filter(trigger =>
        trigger.trigger_name?.toLowerCase().includes(query.toLowerCase()) 
        // ||
        // pipeline.id.toString()?.includes(query.toUpperCase()) ||
        // pipeline.clinic_name?.toLowerCase().includes(query.toLowerCase())
      );

      this.setState({ filteredData: filtered });
    }
  };
  handleNavigation=(row: TriggerDetails | undefined)=>{
    localStorage.setItem("TriggerDetails",JSON.stringify(row))
    this.props.navigation.navigate("TriggerDetails")
}

  // Customizable Area End
}
