import React, { Component } from "react";
import TopNav from "../../../navigationmenu/src/TopNav.web";
import { styled } from "@mui/styles";
import { Box, TextField, MenuItem, Typography, Divider, Autocomplete, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { LevelType, TaskType, UserType } from "../CreatePipelineController";

interface CreateEditPipelineErrorsType {
    pipeline_name?: string
    description?: string
}
interface Props {
    navigation: { goBack: () => void };
    id: string;
    title: string;
    pipeline_name: string;
    handleChangeValue: (e: any) => void;
    description: string;
    handleCreateEditPipeline: (e: any) => void
    handleAutoComplete: (id: string) => (e: any, newValue: any) => void
    allLevels: LevelType[];
    allUsers: UserType[]
    allTasks: TaskType[]
    customSelectedLevels: LevelType[]
    customSelectedUsers: UserType[]
    customSelectedTasks:TaskType[]
    customAssociatedClinic: string
    handleCustomSelectClinic: (clinic: string) => void
    isCustomFormComplete: () => boolean | string
    buttonText: string
    customPipelinesError: CreateEditPipelineErrorsType
}

interface S {
    searchQuery: string
    open: boolean
    filteredOptions: string[]
    openStates: {
        selectedUsers: boolean;
        selectedTasks: boolean;
        selectedLevels: boolean;
    };
}

interface SS {
}

export default class Custompipelinecomp extends Component<Props, S> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchQuery: '',
            open: false,
            filteredOptions: ["Global", "Clinic 1", "Clinic 2", "Clinic 3", "Clinic 4"],
            openStates: {
                selectedUsers: false,
                selectedTasks: false,
                selectedLevels: false,
            },
        }
    }
    handleDropdownToggle = (id: keyof S['openStates']) => {
        this.setState((prevState) => {
            const newOpenStates = {
              selectedUsers: false,
              selectedTasks: false,
              selectedLevels: false,
            };
            newOpenStates[id] = !prevState.openStates[id]; 
      
            return {
              openStates: newOpenStates,
            };
          });
    };
    renderVisibleBySection = (id: keyof S['openStates'], options: any, value: {attributes:{ label: string }}[], placeholder: string) => {
        return (
            <Box style={{ flex: 1 }}>
                <Autocomplete
                    multiple
                    id={id}
                    options={options}
                    open={this.state.openStates[id]}
                    onChange={(event, newValue) => {this.handleDropdownToggle(id);this.props.handleAutoComplete(id)(event, newValue)}}
                    isOptionEqualToValue={(option, value) => option?.attributes?.label === value?.attributes?.label}
                    value={value}
                    getOptionLabel={(option) => option?.attributes?.label}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li
                                {...props}
                                style={{
                                    fontSize: "1.6rem",
                                    backgroundColor: selected ? "#1A4A421A" : "transparent",
                                    fontWeight: 400,
                                    color: selected ? "#1A4A42" : "#0F172A",
                                }}
                            >
                                {option?.attributes?.label}
                            </li>
                        );
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            style={{ marginBottom: "0.8rem", display: "flex", alignItems: 'center' }}
                            {...params}
                            onClick={()=>this.handleDropdownToggle(id)}
                            placeholder={placeholder}
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params?.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end"
                                        sx={{
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"
                                            }
                                        }}
                                    >
                                        {id === "selectedUsers" || id==="selectedTasks" ? <SearchIcon onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>)=>{e?.stopPropagation();this.handleDropdownToggle(id)}} sx={{
                                            color: '#64748B',
                                            cursor: 'pointer',
                                            fontSize: '2rem',
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"

                                            }
                                        }} /> : <ArrowDropDownOutlinedIcon onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>)=>{e?.stopPropagation();this.handleDropdownToggle(id)}} style={{
                                            color: '#64748B',
                                            cursor: 'pointer',
                                            fontSize: '2rem'
                                        }} />}
                                    </InputAdornment>
                                ),
                                sx: {
                                    fontWeight: 400,
                                    paddingLeft: "0.3rem !important",
                                    fontSize: "1.6rem",
                                    flexWrap: 'wrap',
                                    overflowY: 'auto',
                                    display: "flex",
                                    alignItems: 'center',

                                    "& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                        fontSize: "1.6rem",
                                        paddingRight: "0",
                                        display: 'none',
                                    },
                                    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                        paddingRight: "0 !important"
                                    },
                                    "& .MuiAutocomplete-tag": {
                                        display: "none"
                                    }
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    padding: "0 0.3rem",
                                    minHeight: '4rem !important',
                                    overflow: 'auto',

                                },
                                '& .MuiSelect-icon': {
                                    display: 'none',
                                },
                                "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                                    paddingRight: "0 !important"
                                },
                                "& .MuiInputAdornment-root": {
                                    marginRight: '1rem',
                                },
                                "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                    paddingRight: "0 !important",
                                },
                                "& .MuiInputBase-adornedEnd": {
                                    paddingRight: "0 !important",
                                },
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingRight: "0 !important",
                                },

                            }}
                        />
                    )}
                />
                <Box sx={{ marginTop: "0.1rem", display: 'flex', flexWrap:"wrap", gap: '0.5rem', width: '100%' }}>
                    {value.length > 0 && value.map((item: any, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                border: '0.1rem solid #1A4A4266',
                                backgroundColor: "white",
                                borderRadius: "0.8rem",
                                padding: "1rem",
                                fontSize: "1.2rem",
                                fontWeight: 400,
                                color: "#292524",
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                {(() => {
                                    if (item.attributes.source) {
                                        return (
                                            <img
                                                src={item.attributes.source}
                                                style={{ width: '18px', height: '18px', borderRadius: '50%', objectFit: 'cover' }}
                                            />
                                        );
                                    } else if (id === "selectedTasks") {
                                        return <PeopleAltOutlinedIcon style={{ fontSize: '1.8rem', color: "#0F172A" }} />;
                                    } else {
                                        return <CheckCircleOutlineIcon style={{ fontSize: '1.8rem', color: "#0F172A" }} />;
                                    }
                                })()}
                                {item?.attributes?.label}
                            </Box>

                            <CloseIcon
                                sx={{
                                    marginLeft: "0.5rem",
                                    fontSize: "1.6rem",
                                    cursor: "pointer",
                                    color: "#1A4A4266"
                                }}
                                onClick={() =>
                                    this.props.handleAutoComplete(id)(null, value.filter((v: any) => v !== item))
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };
    handleSelectClinic = (option: string) => {
        this.props.handleCustomSelectClinic(option)
        this.setState({ open: false, searchQuery: '' })
    }
    render() {
        const { navigation, buttonText, customPipelinesError, title, pipeline_name, handleChangeValue, description, customAssociatedClinic, handleCreateEditPipeline, allLevels, allTasks, allUsers, customSelectedLevels, customSelectedTasks, customSelectedUsers, isCustomFormComplete } = this.props
        const allClinicsData = ["Global", "Clinic 1", "Clinic 2", "Clinic 3", "Clinic 4"]
        const filteredOptions = allClinicsData.filter((option) => option.toLowerCase().includes(this.state.searchQuery));
        return (
            <Custompipelinecomponent>
                <Box marginBottom={2}>
                    <Box display='flex' alignItems='center' style={{ gap: "1rem" }}>
                        <ArrowBackIcon data-test-id="back-btn" style={{ cursor: "pointer", fontSize: '2rem' }} onClick={navigation.goBack} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{title}</Typography>
                    </Box>
                </Box>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleCreateEditPipeline}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Pipeline Name
                    </Typography>
                    <TextField
                        name="pipeline_name"
                        data-test-id="pipeline_name"
                        value={pipeline_name}
                        onChange={handleChangeValue}
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline name"
                        style={{ marginBottom: "0.8rem" }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '1.1rem! important',
                            }
                        }}
                        inputProps={{
                            sx: {
                                fontWeight: 400,
                                fontSize: '1.6rem',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',

                            },
                        }}
                    />
                    {customPipelinesError.pipeline_name && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{customPipelinesError.pipeline_name}</Typography>
                    )}

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Description
                    </Typography>
                    <TextField
                        name="description"
                        data-test-id="pipeline_desc"
                        value={description}
                        onChange={handleChangeValue}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline description"
                        style={{ marginBottom: "0.8rem" }}
                        sx={{
                            '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                padding: '1rem 0.8rem'
                            }
                        }}
                        multiline
                        rows={3}
                        inputProps={{
                            sx: {
                                fontWeight: 400,
                                fontSize: '1.6rem',
                            },
                        }}
                    />
                    {customPipelinesError.description && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{customPipelinesError.description}</Typography>
                    )}

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Select Levels
                    </Typography>
                    <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                        {this.renderVisibleBySection("selectedLevels", allLevels, customSelectedLevels, "Select Levels")}
                    </Box>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Select Task Groups
                    </Typography>
                    <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                        {this.renderVisibleBySection("selectedTasks", allTasks, customSelectedTasks, "Select Task Groups")}
                    </Box>

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Individuals
                    </Typography>
                    <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                        {this.renderVisibleBySection("selectedUsers", allUsers, customSelectedUsers, "Search Individuals")}
                    </Box>

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Associated Clinic
                    </Typography>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <Box display="flex" style={{ fontSize: '1.6rem', height: '3.7rem', borderRadius: "0.4rem", padding: "0 0.8rem" }} justifyContent="space-between" onClick={() => this.setState(prevState => ({ open: !prevState.open }))} alignItems="center" sx={{ border: '0.1rem solid rgba(0, 0, 0, 0.3)', "&:hover": { borderColor: 'black' } }}>
                            <p style={{ color: `${customAssociatedClinic ? "black" : "rgba(0, 0, 0, 0.4)"}` }}>{customAssociatedClinic || "Search Clinic"}</p>
                            <ArrowDropDownOutlinedIcon style={{
                                color: '#64748B',
                                cursor: 'pointer',
                                fontSize: '2rem'
                            }} />

                        </Box>

                        {this.state.open && (

                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    maxHeight: '20rem',
                                    minHeight: '20rem',
                                    overflowY: 'auto',
                                    zIndex: 10,
                                    backgroundColor: "white",
                                    mt: '4px',
                                    border: '1px solid #ddd',
                                }}
                            >   <Box style={{ padding: '1.8rem', display: "flex", flexDirection: "column", gap: '0.7rem' }}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Search"
                                        value={this.state.searchQuery}
                                        onChange={(e: any) => this.setState({ searchQuery: e.target.value })}
                                        inputProps={{
                                            sx: {
                                                padding: '0.6rem 2rem 0.6rem 0',
                                                fontWeight: 400,
                                                fontSize: '1.6rem',
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ fontSize: "2rem", color: "#94A3B8" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{

                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.6rem',
                                            },
                                        }}
                                    />
                                    {filteredOptions.length > 0 ? (
                                        filteredOptions.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() => this.handleSelectClinic(option)}
                                                sx={{
                                                    fontSize: '1.4rem',
                                                    padding: '0.6rem 0',
                                                    fontWeight: `${customAssociatedClinic == option ? 700 : 400}`,
                                                    color: `${customAssociatedClinic == option ? "#1A4A42" : "#292524"}`,
                                                    '&:hover': { backgroundColor: '#f0f0f0' },
                                                }}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled sx={{ fontSize: '1.4rem' }}>No Associated Clinics found</MenuItem>
                                    )}
                                </Box>
                            </Box>

                        )}</Box>
                    <CustomWarningBox>
                        <CustomCreateEditButton type="submit" disabled={!isCustomFormComplete()} style={{
                            backgroundColor: isCustomFormComplete() ? "#1A4A42" : "#A8A29E66",
                            color: isCustomFormComplete() ? "#fff" : "#78716C",
                            cursor: isCustomFormComplete() ? "pointer" : "not-allowed",
                        }}>{buttonText}</CustomCreateEditButton>
                    </CustomWarningBox>
                </Box>
            </Custompipelinecomponent>
        );
    }
}

const Custompipelinecomponent = styled("div")({
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    minHeight: 'calc(100vh - 2.5rem)'
});

const dividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const CustomWarningBox = styled("div")(
    {
        border: "0.1rem dashed #292524",
        padding: "1.5rem",
        borderRadius: "1.2rem",
        backgroundColor: "#f9f9f9",
        marginTop: "2.5rem"
    });

const CustomCreateEditButton = styled("button")({
    width: "100%",
    padding: "1rem",
    border: "none",
    borderRadius: "0.8rem",
    fontSize: "1.6rem",
    cursor: "pointer",
    fontWeight: 600,
});