// Customizable Area Start
import React from "react";
import { Box, Typography as MuiTypography, IconButton } from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PaginationComponent from "./utils/PaginationComponent";

import TriggersController, {
  Props,
  TriggerData
} from "./TriggersController";
import { LoadingSpinner } from "./CreatePipeline.web";
import { SearchBar } from "./Cfpipelines1.web";
// Customizable Area End

export default class Triggers extends TriggersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderColumnTypography = (flex: number, text: string | number) => {
    return (
      <MuiTypography variant="h6" style={{ fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}</MuiTypography>
    )
  }

  renderRowTypography = (flex: number, text: string | number, row: TriggerData) => {
    return (
      <MuiTypography variant="h6" data-test-id={"row-typography"+flex} style={{
        fontSize: '1.4rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 600, cursor: 'pointer', color: '#203447'
      }} onClick={() => this.handleTriggerNavigate(row)}>{text}</MuiTypography>
    )
  }
  
  triggerList = () => {
    const { currentPage, pageSize, filteredData } = this.state;
    const paginatedRows = filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, filteredData.length);
    const totalResults = filteredData.length;
    return <Box style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>
          Triggers
        </MuiTypography>
        <Box display='flex' alignItems='center' gap={1}>
        <SearchBar data-test-id="btnNav" handleSearchField={this.handleSearchTrigger} />
        </Box>
      </Box>
      <Box display="flex" flexDirection='column' gap={1}>
        <Box display="flex" style={{alignItems: 'center', backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }}>
          {this.renderColumnTypography(6, 'Trigger Name')}
          {this.renderColumnTypography(10, 'Description')}
          {this.renderColumnTypography(1.5, 'Actions')}
        </Box>
        {paginatedRows.length > 0 ? paginatedRows.map(row => (
          <Box data-test-id="btnNavigate" onClick={()=>this.handleNavigation(row.details)} display='flex' key={row.id} style={{ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
            {this.renderRowTypography(6, row.trigger_name, row)}
            {this.renderRowTypography(10, "test", row)}
            <Box style={{flex:1.5}}>
              <IconButton data-test-id="duplicate-btn-test" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() =>{}}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Triggers Found</MuiTypography></Box>}
      </Box>
      <PaginationComponent data-test-id="pagination" currentPageIndex={currentPage} requiredPageSize={pageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChange}/>     
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineListContainerStyle}>
        {this.state.isLoading ?
                <LoadingSpinner />
                :
        this.triggerList()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const pipelineListContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
};
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.6rem",
  borderRadius: "0.6rem",
};

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width: '28rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 2,
}
// Customizable Area End
