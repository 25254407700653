// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TriggerDetails } from "./TriggersController";
export interface TriggerData {
id:number,
trigger_name:string,
selection1:string,
value1:string,
selection2:string,
value2:string,
created_at:string,
updated_at:string
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage: number,
  pageSize: number,
  rowData: TriggerData[],
  filteredData: TriggerData[],
  searchQuery: string
  isLoading:boolean
  selectedParamter:string
  isOpen:boolean
  triggerDetail:TriggerDetails
  expandedParameterIndex:number[]
  noDetails:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TriggerDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    triggerListCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 0,
      pageSize: 10,
      rowData: [],
      filteredData: [],
      searchQuery: '',
      isLoading:true,
      selectedParamter:"Parameter 1",
      isOpen:false,
      triggerDetail: {
        id: 0,
        trigger_name: "",
        description: "",
        parameters: [],
        examples: [],
      },
      expandedParameterIndex:[],
      noDetails:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    let details = localStorage.getItem("TriggerDetails");
    console.log("details",details)
    if (details && details !== "undefined") {
      this.setState({ triggerDetail: JSON.parse(details) });
    } else {
      this.setState({ noDetails: true });
    }  }

  handleExpand=(index: number)=>{
    const expandedParameterIndex = [...this.state.expandedParameterIndex];
    if (expandedParameterIndex.includes(index)) {
      expandedParameterIndex.splice(expandedParameterIndex.indexOf(index), 1);
    } else {
      expandedParameterIndex.push(index);
    }
    this.setState({ expandedParameterIndex });
  }


  // Customizable Area End
}
