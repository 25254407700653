// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import TriggerDetailsController, {
  Props
} from "./TriggerDetailsController";
import { arrowImg, LongDownArrow, LongUpArrow } from "./assets";
// Customizable Area End

export default class TriggerDetails extends TriggerDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {
          this.state.noDetails ?
            <Grid
              container
              md={12}
              display="flex"
              sx={{
                height: "100vh",
              }}
              margin={1}
              flexDirection="column"
            >
              <Box display="flex" alignItems="center" gap={1} sx={{}}>
                <ArrowBackIcon
                  data-test-id="arrow_back_empty"
                  style={{ fontSize: "2rem", cursor: "pointer" }}
                  onClick={() => this.props.navigation.goBack()}
                />
                <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "2.2rem", color: "#292524" }}>
                  Trigger Details
                </Typography>
              </Box>

              <Grid item container alignItems="center" justifyContent="center">

                <Typography variant="h4" sx={{ marginTop: 12, textAlign: "center", fontWeight: 400, color: "#292524" }}>
                  Trigger Details is not available.
                </Typography>
              </Grid>
            </Grid>

            :
            <Grid container flexDirection={"column"} alignSelf={"center"}
              sx={{ ...detailAccountGroupContainer }}
            >
              <Grid item xs={12} md={12} sm={12} lg={12}  >
                <Box display="flex" justifyContent='space-between' marginBottom={2}>
                  <Box display='flex' alignItems='center' gap={1}>
                    <ArrowBackIcon data-test-id="arrow_back" style={{ fontSize: '2rem' }} onClick={() => this.props.navigation.goBack()} />
                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{this.state.triggerDetail.trigger_name}</Typography>
                  </Box>
                </Box>
                <Box display='flex' flexDirection='column' flexWrap='wrap' gap={1} marginBottom={4}>
                  <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Trigger Details</Typography>
                  <Box display='flex' flexDirection='column' flexWrap='wrap' gap={0.5}>
                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DESCRIPTION</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#292524" }}>{this.state.triggerDetail.description}</Typography>
                  </Box>
                </Box>
                {
                  this.state.triggerDetail.parameters.map((element: any, index: any) => (
                    <>
                      <Box data-test-id="btnParameter" onClick={() => this.handleExpand(index)} display={"flex"} marginBottom={2} flexDirection={"row"}
                        sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
                        <Typography paddingLeft={0.5} margin={0.5} variant="h6" sx={{ fontWeight: 700, fontSize: '1.4rem', color: "#F4F4F4F4" }}>
                          {element.parameter}
                        </Typography>
                        {
                          this.state.expandedParameterIndex.includes(index) ?
                            <ArrowDropDownRoundedIcon
                              style={{ marginRight: 5, alignSelf: "center" }}
                            /> :
                            <ArrowRightRoundedIcon
                              style={{ marginRight: 5, alignSelf: "center", color: "white" }}
                            />
                        }
                      </Box>

                      {this.state.expandedParameterIndex.includes(index) &&

                        <Grid item xs={10.8} sm={6} md={12} lg={12} xl={12}  >
                          <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={"center"} >

                            <Grid item xs={10.8} md={3} className="test">
                              <Grid container flexDirection={"column"} height={"176px"}
                                sx={{
                                  display: "flex",
                                  alignItems: "center", padding: 1.5,
                                  background: "#1A4A424D", borderRadius: "8px"
                                }}>
                                <Typography marginBottom={1.5} alignSelf={"flex-start"} sx={{
                                  fontWeight: "700", fontFamily: "Raleway",
                                }} >{element.parent_values.parent_parameter}</Typography>

                                <Grid item marginBottom={1.5}
                                  sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "#1A4A4280", border: "1px dashed white" }} >
                                  <Typography sx={{ fontSize: "10px", color: "white", margin: 1, fontStyle: "italic", fontWeight: "700" }} >
                                    {element.parent_values.parent_parameter_content}
                                  </Typography>
                                </Grid>
                                <Typography alignSelf={"flex-start"} sx={{
                                  fontFamily: "Raleway",
                                }} >
                                  {element.parent_values.parent_parameter_description}
                                </Typography>
                              </Grid>


                            </Grid>

                            <img src={arrowImg} />
                            <Grid item xs={10.8} md={3} className="test">
                              <Grid container flexDirection={"column"} display={"flex"} height={"176px"}
                                sx={{
                                  display: "flex",
                                  alignItems: "center", padding: 1.5,
                                  background: "#1A4A424D", borderRadius: "8px"
                                }}>
                                <Typography marginBottom={1.5} alignSelf={"flex-start"} sx={{
                                  fontWeight: "700", fontFamily: "Raleway",
                                }} >{element.child_values.child_parameter}</Typography>

                                <Grid item marginBottom={1.5}
                                  sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "#1A4A4280", border: "1px dashed white" }} >
                                  <Typography sx={{ fontSize: "10px", color: "white", margin: 1, fontStyle: "italic", fontWeight: "700" }} >
                                    {element.child_values.child_parameter_content}
                                  </Typography>
                                </Grid>
                                <Typography alignSelf={"flex-start"} sx={{
                                  fontFamily: "Raleway",
                                }} >
                                  {element.child_values.child_parameter_description}
                                </Typography>
                              </Grid>


                            </Grid>
                            {element.child_values.child_parameter_types.length == 1 ? <Grid item >
                              <Grid container flexDirection={"column"}>
                                <img src={arrowImg} style={{ marginTop: 20, marginBottom: 20 }} />
                              </Grid>
                            </Grid> :
                              <Grid item >
                                <Grid container flexDirection={"column"}>
                                  <img src={LongUpArrow} style={{ height: "60vh" }} />
                                  <img src={arrowImg} style={{ marginTop: 20, marginBottom: 20 }} />
                                  <img src={LongDownArrow} style={{ height: "60vh" }} />
                                </Grid>
                              </Grid>
                            }


                            <Grid item sm={12} md={5} xs={12} >
                              {
                                element.child_values.child_parameter_types.map((item: { heading: string; conditionData: any[]; description: string }) => (
                                  <Grid item marginBottom={2} flexDirection={"column"} >
                                    <Typography marginBottom={0.5}>{item.heading}</Typography>
                                    <Grid item
                                      sx={{
                                        padding: 0,
                                        background: "#1A4A424D",
                                        borderRadius: "8px",
                                      }}>
                                      <Grid container marginBottom={1} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                        <Grid item sm={4.5} md={5} xs={4.5} flexDirection={"column"} >
                                          <Typography
                                            sx={{ fontSize: "12px", color: "#000", margin: 0.5, fontWeight: "700" }}
                                          >
                                            Condition
                                          </Typography>
                                          <Grid item container sx={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            background: "#1A4A4233",
                                            border: "1px dashed #1A4A42"
                                          }}>
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "#1A4A42",
                                                margin: 0.5,
                                                fontStyle: "italic",
                                                fontWeight: "700",
                                              }}
                                            >
                                              {item.conditionData[0].condition}
                                            </Typography>

                                          </Grid>
                                        </Grid>
                                        <Grid item >
                                          <img src={arrowImg} style={{ marginTop: 26 }} />
                                        </Grid>
                                        <Grid item sm={4.5} md={5} xs={4.5} flexDirection={"column"}>
                                          <Typography
                                            sx={{ fontSize: "12px", color: "#000", margin: 0.5, fontWeight: "700" }}
                                          >
                                            Input
                                          </Typography>
                                          <Grid item container sx={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            background: "#1A4A4233",
                                            border: "1px dashed #1A4A42"
                                          }}>
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "#1A4A42",
                                                margin: 0.5,
                                                fontStyle: "italic",
                                                fontWeight: "700"
                                              }}
                                            >
                                              {item.conditionData[0].input}
                                            </Typography>

                                          </Grid>
                                        </Grid>
                                      </Grid>


                                      {item.conditionData.slice(1).map((condItem, index) => (
                                        <Grid container marginBottom={1} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                                          <Grid item sm={4.5} md={5} xs={4.5} flexDirection={"column"} >
                                            <Grid item container sx={{
                                              alignItems: "center",
                                              justifyContent: "center",
                                              background: "#1A4A4233",
                                              border: "1px dashed #1A4A42"
                                            }}>
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  color: "#1A4A42",
                                                  margin: 0.5,
                                                  fontStyle: "italic",
                                                  fontWeight: "700"
                                                }}
                                              >
                                                {condItem.condition}
                                              </Typography>

                                            </Grid>
                                          </Grid>
                                          <Grid item >
                                            <img src={arrowImg} />
                                          </Grid>
                                          <Grid item sm={4.5} md={5} xs={4.5} flexDirection={"column"}>
                                            <Grid item container sx={{
                                              alignItems: "center",
                                              justifyContent: "center",
                                              background: "#1A4A4233",
                                              border: "1px dashed #1A4A42"
                                            }}>
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  color: "#1A4A42",
                                                  margin: 0.5,
                                                  fontStyle: "italic",
                                                  fontWeight: "700"
                                                }}
                                              >
                                                {condItem.input}

                                              </Typography>

                                            </Grid>
                                          </Grid>
                                        </Grid>


                                      ))}


                                      <Grid item paddingLeft={2} paddingRight={2} paddingBottom={2}>
                                        <Typography
                                          sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "12px" }}
                                        >
                                          {item.description}      </Typography>

                                      </Grid>

                                    </Grid>
                                  </Grid>
                                ))
                              }
                            </Grid>
                          </Grid>
                        </Grid>}
                    </>
                  ))
                }


              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Grid container flexDirection={"column"}>
                  <Typography sx={{ fontSize: "14px", color: "#000", marginBottom: 2, marginTop: 2, fontWeight: "700" }} >
                    Examples
                  </Typography>
                  {this.state.triggerDetail.examples.map((element: { name: string; parameter_values: { parameter: string; parent_parameter: string; child_parameter: string; condition: string; input: string }; description: string }) => (
                    <Grid item marginBottom={1} container xs={12} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", background: "#1A4A421A", borderRadius: 2, padding: 1.5 }}>
                      <Typography sx={{ alignSelf: "flex-start", fontSize: "14px", color: "#000", margin: 0.5, fontWeight: "700" }} >
                        {element.name}
                      </Typography>
                      <Grid item sx={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "12px", color: "#000", margin: 0.5, fontWeight: "700" }} >
                          {element.parameter_values.parameter}
                        </Typography>

                        <Grid item container xs={12} sm={8} md={12} lg={12} xl={12} margin={1} sx={{ alignItems: "center", display: "flex", alignSelf: "center" }}>
                          <Grid item
                            sx={{ display: "flex", width: 180, alignItems: "center", justifyContent: "center", background: "#1A4A4280", border: "1px dashed #fff" }} >
                            <Typography sx={{ fontSize: "10px", color: "#fff", margin: 1, fontStyle: "italic", fontWeight: "700" }} >
                              {element.parameter_values.parent_parameter}
                            </Typography>
                          </Grid>
                          <img src={arrowImg} />
                          <Grid item
                            sx={{ display: "flex", width: 180, alignItems: "center", justifyContent: "center", background: "#1A4A4266", border: "1px dashed #1A4A42" }} >
                            <Typography sx={{ fontSize: "10px", color: "#1A4A42", margin: 1, fontStyle: "italic", fontWeight: "700" }} >
                              {element.parameter_values.child_parameter}
                            </Typography>
                          </Grid>
                          <img src={arrowImg} />
                          <Grid item
                            sx={{ display: "flex", width: 180, alignItems: "center", justifyContent: "center", background: "#1A4A4233", border: "1px dashed #1A4A42" }} >
                            <Typography sx={{ fontSize: "10px", color: "#1A4A42", margin: 1, fontStyle: "italic", fontWeight: "700" }} >
                              {element.parameter_values.condition}
                            </Typography>
                          </Grid>
                          <img src={arrowImg} />
                          <Grid
                            sx={{ display: "flex", width: 180, alignItems: "center", justifyContent: "center", background: "#1A4A4233", border: "1px dashed #1A4A42" }} >
                            <Typography sx={{ fontSize: "10px", color: "#1A4A42", margin: 1, fontStyle: "italic", fontWeight: "700" }} >
                              {element.parameter_values.input}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography sx={{ fontSize: "14px", color: "#000", marginTop: 1, marginBottom: 1, fontWeight: "400" }} >
                        {element.description}
                      </Typography>
                    </Grid>
                  ))}

                </Grid>
              </Grid>
            </Grid>
        }
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const detailAccountGroupContainer = {
  padding: "2rem 2rem ",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
  minHeight: 'calc(100vh - 2.5rem)'
};

// Customizable Area End
