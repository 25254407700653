Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.updateApiMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpipelines1";
exports.labelBodyText = "cfpipelines1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getPipelineDataEndPoint='bx_block_cfpipelines1/pipelines';
exports.stageApiEndPoint ='bx_block_cfstages1/stages'
exports.triggersApiEndPoint ='bx_block_cfpipelines1/triggers'
exports.eventsApiEndPoint ='bx_block_cfpipelines1/events'
exports.ruleBuilderApiEndPoint='bx_block_cfpipelines1/rule_builders'
exports.createPipelineApiEndPoint="/bx_block_cfpipelines1/pipelines"
exports.postMethod="POST"
exports.getMethod="GET"
exports.pipelineListApiEndPoint="/bx_block_cfpipelines1/pipelines"
exports.deleteMethod='delete'
exports.deletePipelineApiEndPoint="/bx_block_cfpipelines1/pipelines/"
exports.triggerListApiEndPoint="/bx_block_cfpipelines1/triggers"
exports.actionsListApiEndPoint="/bx_block_cfpipelines1/actions"
exports.levelsListApiEndPoint="/bx_block_roles_permissions/permissions"
exports.patchMethod="PATCH"
// Customizable Area End